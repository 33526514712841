<template>
    <div>
        <v-app>
            <KTPortlet
                v-bind="{
            title: 'Packaging Setting',
            headSize: 'lg',
            fluidHeight: true,
            headOverlay: true,
            headNoBorder: true
          }"
            >
                <template v-slot:toolbar>
                    <v-container>
                        <v-btn @click="openDialog" depressed small>Add New</v-btn>
                    </v-container>
                </template>
                <template v-slot:body>
                    <v-card flat>
                        <v-card-title>
                            <v-spacer></v-spacer>
                        </v-card-title>

                        <v-data-table :headers="headers" :items="data" :search="search">
                            <template v-slot:item.is_active="{ item }">
                <span class="badge" :class="item.is_active?'badge-success':'badge-danger'">
                  <i class="fa" :class="item.is_active?'fa-check':'fa-ban'"></i>
                </span>
                            </template>
                            <template v-slot:item.weight="{ item }">{{item.weight}} {{item.weight_unit}}</template>
                            <template v-slot:item.actions="{ item }">
                                <v-icon small class="mr-2" @click="editPackage(item)">mdi-pencil</v-icon>
                                <v-icon small @click.prevent="_delete(item.id)">mdi-delete</v-icon>
                            </template>
                        </v-data-table>
                    </v-card>
                </template>
            </KTPortlet>
            <v-dialog scrollable v-model="newDialog" persistent max-width="900">
                <v-card>
                    <v-card-title class="headline">Add Package</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="6" sm="12" md="6" lg="6">
                                <v-text-field label="Title *" v-model="package.title" dense outlined></v-text-field>
                                <span class="text-danger"
                                      v-if="$v.package.title.$error">{{validationMessage.title}}</span>
                            </v-col>
                            <v-col cols="6" sm="12" md="6" lg="6">
                                <v-select
                                    :items="types"
                                    v-model="package.type"
                                    item-text="title"
                                    item-value="value"
                                    label="Type *"
                                    dense
                                    outlined
                                ></v-select>
                                <span class="text-danger"
                                      v-if="$v.package.type.$error">{{validationMessage.type}}</span>
                            </v-col>

                            <v-col cols="3" sm="12" md="3" lg="3">
                                <v-text-field
                                    label="Length *"
                                    type="number"
                                    min="0"
                                    v-model="package.length"
                                    dense
                                    outlined
                                ></v-text-field>
                                <span
                                    class="text-danger"
                                    v-if="$v.package.length.$error"
                                >{{validationMessage.length}}</span>
                            </v-col>
                            <v-col cols="3" sm="12" md="3" lg="3">
                                <v-text-field
                                    label="Width *"
                                    type="number"
                                    min="0"
                                    v-model="package.width"
                                    dense
                                    outlined
                                ></v-text-field>
                                <span class="text-danger"
                                      v-if="$v.package.width.$error">{{validationMessage.width}}</span>
                            </v-col>
                            <v-col cols="3" sm="12" md="3" lg="3">
                                <v-text-field
                                    label="Height *"
                                    type="number"
                                    min="0"
                                    v-model="package.height"
                                    dense
                                    outlined
                                ></v-text-field>
                            </v-col>
                            <v-col cols="3" sm="12" md="3" lg="3">
                                <v-select
                                    :items="measurement_units"
                                    v-model="package.measurement_unit"
                                    item-text="title"
                                    item-value="value"
                                    label="Measurement Unit *"
                                    dense
                                    outlined
                                ></v-select>
                                <span
                                    class="text-danger"
                                    v-if="$v.package.weight.$error"
                                >{{validationMessage.weight}}</span>
                            </v-col>
                            <v-col cols="6" sm="12" md="6" lg="6">
                                <v-text-field
                                    type="number"
                                    min="0"
                                    label="Weight *"
                                    v-model="package.weight"
                                    dense
                                    outlined
                                ></v-text-field>
                                <span
                                    class="text-danger"
                                    v-if="$v.package.weight.$error"
                                >{{validationMessage.weight}}</span>
                            </v-col>
                            <v-col cols="6" sm="12" md="6" lg="6">
                                <v-select
                                    :items="weight_units"
                                    v-model="package.weight_unit"
                                    item-text="title"
                                    item-value="value"
                                    label="Weight Unit *"
                                    dense
                                    outlined
                                ></v-select>
                                <span
                                    class="text-danger"
                                    v-if="$v.package.weight_unit.$error"
                                >{{validationMessage.weight_unit}}</span>
                            </v-col>

                            <v-col cols="6" sm="12" md="6" lg="6">
                                <v-switch dense v-model="package.is_active" :label="'Status'"></v-switch>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <!-- <pre>{{package}}</pre> -->
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text :color="'red'" @click="closeDialog">Cancel</v-btn>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="createOrUpdate"
                            v-text="this.edit ? 'Edit' : 'Add'"
                        >Add
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-app>
    </div>
</template>
<script>
    import KTPortlet from "@/views/partials/content/Portlet.vue";
    import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
    import WebsitePackageSettingService from "@/services/Websites/WebsitePackageSettingService";
    import {required} from "vuelidate/lib/validators";

    const PackageSettingService = new WebsitePackageSettingService();
    export default {
        name: "package-setting",
        components: {
            KTPortlet
        },
        validations: {
            package: {
                title: {required},
                length: {required},
                width: {required},
                weight: {required},
                weight_unit: {required},
                measurement_unit: {required},
                type: {required}
            }
        },
        data() {
            return {
                courirers: [],
                // siteUrl:null,
                newDialog: false,
                search: "",
                edit: false,
                page: null,
                measurement_units: [
                    {title: "Milimeter", value: "mm"},
                    {title: "Centimeter", value: "cm"},
                    {title: "Meter", value: "m"},
                    {title: "Feet", value: "ft"},
                    {title: "Inch", value: "inch"}
                ],
                weight_units: [
                    {title: "Gram", value: "g"},
                    {title: "Kilogram", value: "kg"},
                    {title: "Ton", value: "ton"}
                ],
                types: [
                    {title: "Parcel", value: "parcel"},
                    {title: "Letter", value: "letter"}
                ],
                package: {
                    title: null,
                    length: null,
                    width: null,
                    height: null,
                    weight: null,
                    weight_unit: null,
                    measurement_unit: null,
                    type: null,
                    is_active: 1
                },
                validationMessage: {
                    title: "Title is required field",
                    length: "Length is required field",
                    width: "Width is required field",
                    weight: "Weight is required field",
                    weight_unit: "Weight unit is required field",
                    measurement_unit: "Measurement unit is required field",
                    type: "Type is required field"
                },
                headers: [
                    {
                        text: "Title",
                        align: "start",
                        sortable: false,
                        value: "title"
                    },
                    {text: "Length", value: "length"},
                    {text: "Width", value: "width"},
                    {text: "Height", value: "height"},
                    {text: "Weight", value: "weight"},
                    {text: "Measurement Unit", value: "measurement_unit"},
                    {text: "Type", value: "type"},
                    {text: "Status", value: "is_active"},
                    {text: "Action", value: "actions"}
                ],
                data: []
            };
        },
        methods: {
            getPackages() {
                PackageSettingService.paginate(this.siteUrl)
                    .then(response => {
                        this.data = response.data.data;
                    })
                    .catch(error => {
                        // console.log(error);
                    });
            },
            openDialog() {
                this.newDialog = true;
            },
            closeDialog() {
                this.newDialog = false;
            },
            editPackage(item) {
                this.package = item;
                this.edit = true;
                this.openDialog();
            },
            createOrUpdate() {
                this.$v.package.$touch();
                if (this.$v.$error) {
                    setTimeout(() => {
                        this.$v.$reset();
                    }, 3000);
                } else {
                    if (!this.edit) this.create();
                    else this.update();
                }
            },

            create: function () {
                // console.log(this.package);
                PackageSettingService.create(this.siteUrl, this.package)
                    .then(response => {
                        this.$snotify.success("Package created successfully");
                        this.resetForm();
                    })
                    .catch(error => {
                        this.errors = error.response.data.errors;
                    });
            },
            update: function () {
                PackageSettingService.update(this.siteUrl, this.package.id, this.package)
                    .then(response => {
                        this.$snotify.success("Package updated successfully");
                        this.resetForm();
                    })
                    .catch(error => {
                        this.errors = error.response.data.errors;
                    });
            },
            _delete: function (id) {
                this.$confirm(
                    {
                        message: `Are you sure?`,
                        button: {
                            no: 'No',
                            yes: 'Yes'
                        },
                        /**
                         * Callback Function
                         * @param {Boolean} confirm
                         */
                        callback: confirm => {
                            if (confirm) {
                                PackageSettingService.delete(this.siteUrl, id)
                                    .then(response => {
                                        this.$snotify.success("Package deleted successfully");
                                        this.resetForm();
                                    })
                                    .catch(error => {
                                        // console.log(error);
                                    });
                            }
                        }
                    }
                )

            },
            resetForm() {
                this.edit = false;
                this.closeDialog();
                this.package = {
                    title: null,
                    length: null,
                    width: null,
                    height: null,
                    weight: null,
                    weight_unit: null,
                    measurement_unit: null,
                    type: null,
                    is_active: 1
                };
                this.$v.$reset();
                this.getPackages(this.page);
            }
        },
        computed: {
            rows() {
                return this.notices.length;
            },
            siteUrl() {
                return this.$route.params.domainname;
            },
            generateSlug() {
                return this.slugify(this.page.title);
            }
        },
        mounted() {
            // this.siteUrl = this.$route.params.domainname;
            this.$store.dispatch(SET_BREADCRUMB, [{title: "Packaging Setting"}]);
            this.getPackages();
        }
    };
</script>
