import Apiservice from '../../common/api.service';
import { API_URL } from '../../common/config.js'
const apiService = Apiservice;


export default class WebsiteShippingDetails {

    paginate(slug, index = null) {
        let url = API_URL + 'user/website/' + slug + '/shipping-detail';
        return apiService.get(url);
    }

    create(slug, data) {
        let url = API_URL + 'user/website/' + slug + '/shipping-detail'
        return apiService.post(url, data);
    }

    update(slug, id, data) {
        let url = API_URL + 'user/website/' + slug + '/shipping-detail/' + id
        return apiService.put(url, data);
    }

    delete(slug, id) {
        let url = API_URL + 'user/website/' + slug + '/shipping-detail/' + id
        return apiService.delete(url);
    }
    show(slug, id) {
        let url = API_URL + 'user/website/' + slug + '/shipping-detail/' + id
        return apiService.get(url);
    }


}
