import Apiservice from '../../common/api.service';
import { API_URL } from '../../common/config.js'
const apiService = Apiservice;


export default class WebsitePackageSettingService {

    all(slug, type = null, flag = null, index = null, ) {
        let url = API_URL + 'user/website/' + slug + '/all/package-setting';
        if (index != null)
            url = url + '?page=' + index
        if (type != null)
            url = url + '?type=' + type
        if (flag != null)
            url = url + '?flag=' + flag
        return apiService.get(url);
    }

    paginate(slug, type = null, flag = null, index = null, ) {
        let url = API_URL + 'user/website/' + slug + '/package-setting';
        if (index != null)
            url = url + '?page=' + index
        if (type != null)
            url = url + '?type=' + type
        if (flag != null)
            url = url + '?flag=' + flag
        return apiService.get(url);
    }

    create(slug, data) {
        let url = API_URL + 'user/website/' + slug + '/package-setting'
        return apiService.post(url, data);
    }


    update(slug, id, data) {
        let url = API_URL + 'user/website/' + slug + '/package-setting/' + id
        return apiService.put(url, data);
    }

    delete(slug, id) {
        let url = API_URL + 'user/website/' + slug + '/package-setting/' + id
        return apiService.delete(url);
    }
    show(slug, id) {
        let url = API_URL + 'user/website/' + slug + '/package-setting/' + id
        return apiService.get(url);
    }




}
