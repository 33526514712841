<template>
    <div>
        <h3>Shipping and Delivery</h3>

        <v-btn
            text
            :to="{ name: 'manage-websites', params: { domainname: siteUrl }}"
            outlined
            class="btn btn-lg btn-secondary-main"
            style="background:transparent;"
        >
            <i class="fas fa-arrow-left"></i> Back
        </v-btn>
        <span class="btn btn-label-primary btn-sm btn-bold kt-margin-r-10 pull-right">
      <i class="fa fa-globe"></i>
      {{siteUrl}}
    </span>
        <v-app>
            <v-card>
                <v-tabs
                    v-model="tab"
                    centered
                    icons-and-text
                >
                    <v-tabs-slider></v-tabs-slider>

                    <v-tab>
                        Delivery Option
                        <v-icon>fas fa-shipping-fast</v-icon>
                    </v-tab>

                    <v-tab>
                        Shippments
                        <v-icon>
                            fas fa-box-open
                        </v-icon>
                    </v-tab>

                </v-tabs>

                <v-tabs-items v-model="tab">
                    <v-tab-item>
                        <v-card flat>
                            <v-card-text>
                                <v-row class="pa-10">
                                    <v-col>
                                        <h3>Delivery Options</h3>
                                    </v-col>
                                    <v-col cols="12" class="text-right">
                                        <v-btn @click="openShipmentAddDialog" outlined text>Add Shipment</v-btn>
                                    </v-col>

                                    <v-row>
                                        <v-expansion-panels>
                                            <v-expansion-panel v-for="(item,i) in shippmentDetails" :key="item.title">
                                                <v-expansion-panel-header>
                                                    Delivery
                                                    <strong v-if="item.free_delivery">Free</strong>
                                                    <span>{{item.type=='within_store_area' ? 'Inside' : ' Outside'}} Your Area</span>
                                                </v-expansion-panel-header>
                                                <v-expansion-panel-content>
                                                    <v-row>
                                                        <v-col class="d-flex" cols="12" sm="6">
                                                            <v-text-field
                                                                outlined
                                                                dense
                                                                v-model="item.title"
                                                                label="Delivery Option Name"
                                                            ></v-text-field>
                                                        </v-col>
                                                        <v-col class="d-flex" cols="12" sm="6">
                                                            <v-select
                                                                :items="[{label:'Inside Store Area',value:'within_store_area'},{label:'Outside Store Area',value:'outside_store_area'},]"
                                                                label="Type"
                                                                item-text="label"
                                                                item-value="value"
                                                                v-model="item.type"
                                                                dense
                                                                outlined
                                                            ></v-select>
                                                        </v-col>
                                                        <v-col class="d-flex" cols="12" sm="4"
                                                               v-if="!item.free_delivery">
                                                            <v-text-field
                                                                outlined
                                                                dense
                                                                v-model="item.delivery_charge"
                                                                prefix="Rs."
                                                                label="Delivery Price"
                                                            ></v-text-field>
                                                        </v-col>
                                                        <v-col class="d-flex" cols="12" sm="4"
                                                               v-if="item.free_delivery">
                                                            <v-text-field
                                                                outlined
                                                                dense
                                                                v-model="item.free_delivery_min_amount"
                                                                prefix="Rs."
                                                                label="Above Minimum Amount"
                                                            ></v-text-field>
                                                        </v-col>
                                                        <v-col class="d-flex" cols="12" sm="4" v-else>
                                                            <v-text-field
                                                                outlined
                                                                dense
                                                                v-model="item.min_delivery_amount"
                                                                prefix="Rs."
                                                                label="Above Minimum Amount"
                                                            ></v-text-field>
                                                        </v-col>
                                                        <v-col class="d-flex" cols="12" sm="4">
                                                            <v-switch v-model="item.free_delivery"
                                                                      label="Free Delivery"></v-switch>
                                                        </v-col>
                                                        <v-col cols="12">
                                                            <v-textarea
                                                                outlined
                                                                dense
                                                                v-model="item.delivery_details"
                                                                label="Short information on this delivery"
                                                            ></v-textarea>
                                                        </v-col>
                                                        <v-col cols="12" class="text-right">
                                                            <v-btn @click="saveOrUpdateDelivery(item)" color="primary">
                                                                Upadte
                                                            </v-btn>
                                                            <v-btn @click="deleteShipment(item.id)" color="danger">
                                                                Delete
                                                            </v-btn>
                                                        </v-col>
                                                        <v-col cols="12">
                                                            <hr/>
                                                        </v-col>
                                                    </v-row>
                                                </v-expansion-panel-content>
                                            </v-expansion-panel>
                                        </v-expansion-panels>
                                    </v-row>
                                </v-row>
                            </v-card-text>
                            <v-card-actions>
                                <div class="justify-end"></div>
                            </v-card-actions>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <packagingSetting></packagingSetting>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
            <v-dialog scrollable v-model="addshipmentDialog" non-persistent max-width="900">
                <v-card>
                    <v-card-title class="headline">Add Shipment</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <h4>
                                    Delivery
                                    <strong v-if="deliverShipment.free_delivery">Free</strong>
                                    <span>{{deliverShipment.type=='within_store_area' ? 'Inside Your' : deliverShipment.type=='outside_store_area' ? ' Outside Your':''}} Area</span>
                                </h4>
                            </v-col>
                            <v-col class="d-flex" cols="12" sm="6">
                                <v-text-field
                                    outlined
                                    dense
                                    v-model="deliverShipment.title"
                                    label="Delivery Option Name"
                                ></v-text-field>
                            </v-col>
                            <v-col class="d-flex" cols="12" sm="6">
                                <v-select
                                    :items="[{label:'Inside Store Area',value:'within_store_area'},{label:'Outside Store Area',value:'outside_store_area'},]"
                                    label="Type"
                                    item-text="label"
                                    item-value="value"
                                    v-model="deliverShipment.type"
                                    dense
                                    outlined
                                ></v-select>
                            </v-col>
                            <v-col class="d-flex" cols="12" sm="4" v-if="!deliverShipment.free_delivery">
                                <v-text-field
                                    outlined
                                    dense
                                    v-model="deliverShipment.delivery_charge"
                                    prefix="Rs."
                                    label="Delivery Price"
                                ></v-text-field>
                            </v-col>
                            <v-col class="d-flex" cols="12" sm="4" v-if="deliverShipment.free_delivery">
                                <v-text-field
                                    outlined
                                    dense
                                    v-model="deliverShipment.free_delivery_min_amount"
                                    prefix="Rs."
                                    label="Above Minimum Amount"
                                ></v-text-field>
                            </v-col>
                            <v-col class="d-flex" cols="12" sm="4" v-else>
                                <v-text-field
                                    outlined
                                    dense
                                    v-model="deliverShipment.min_delivery_amount"
                                    prefix="Rs."
                                    label="Above Minimum Amount"
                                ></v-text-field>
                            </v-col>
                            <v-col class="d-flex" cols="12" sm="4">
                                <v-switch v-model="deliverShipment.free_delivery" label="Free Delivery"></v-switch>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea
                                    outlined
                                    dense
                                    v-model="deliverShipment.delivery_details"
                                    label="Short information on this delivery"
                                ></v-textarea>
                            </v-col>
                            <v-col cols="12" class="text-right">
                                <v-btn @click="closeShipmentAddDialog" color="primary" class="m-2">Cancel</v-btn>
                                <v-btn @click="saveOrUpdateDelivery(deliverShipment)" color="primary">Save</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-app>
    </div>
</template>
<script>

    import WebsiteSetting from "@/services/Websites/WebsiteSettingService";


    import WebsiteShippingDetails from "@/services/Websites/WebsiteShippingDetails";
    import PackagingSetting from "./PackagingSetting";

    const WebsiteSettingService = new WebsiteSetting();
    const WebsiteShipping = new WebsiteShippingDetails();

    export default {
        name: "curr",
        data() {
            return {
                pageSetting: null,
                loading: false,
                tab: null,
                addshipmentDialog: false,
                deliverShipment: {
                    id: null,
                    website_id: null,
                    title: null,
                    type: null,
                    free_delivery: 0,
                    is_delivered_online: 0,
                    delivery_charge: 0,
                    estimated_days: null,
                    delivery_details: null,
                    min_delivery_amount: null,
                    free_delivery_min_amount: null
                },
                deliverInside: {
                    id: null,
                    website_id: null,
                    title: null,
                    type: "within_store_area",
                    free_delivery: 0,
                    is_delivered_online: 0,
                    delivery_charge: 0,
                    estimated_days: null,
                    delivery_details: null,
                    min_delivery_amount: null,
                    free_delivery_min_amount: null
                },
                deliverOutside: {
                    id: null,
                    website_id: null,
                    title: null,
                    type: "outside_store_area",
                    free_delivery: 0,
                    is_delivered_online: 0,
                    delivery_charge: 0,
                    estimated_days: null,
                    delivery_details: null,
                    min_delivery_amount: null,
                    free_delivery_min_amount: null
                },
                shippmentDetails: [],

            };
        },
        components: {
            PackagingSetting
        },
        mounted() {
            this.getSettings()
            this.getShippingDetails();

        },
        methods: {
            openShipmentAddDialog() {
                this.addshipmentDialog = true;
            },
            closeShipmentAddDialog() {
                this.addshipmentDialog = false;
            },
            resetDeliver() {
                this.deliverShipment = {
                    id: null,
                    website_id: null,
                    title: null,
                    type: null,
                    free_delivery: 0,
                    is_delivered_online: 0,
                    delivery_charge: 0,
                    estimated_days: null,
                    delivery_details: null,
                    min_delivery_amount: null,
                    free_delivery_min_amount: null
                };
            },

            saveOrUpdateDelivery(item) {
                if (item.id == null) {
                    WebsiteShipping.create(this.siteUrl, item)
                        .then(res => {
                            this.getShippingDetails();
                            this.closeShipmentAddDialog();
                            this.resetDeliver();
                            this.$snotify.success("Shipping Detail  Created");
                        })
                        .catch(err => {
                        });
                } else {
                    WebsiteShipping.update(this.siteUrl, item.id, item)
                        .then(res => {
                            this.getShippingDetails();
                            this.resetDeliver();
                            this.$snotify.success("Shipping Detail  Updated");
                        })
                        .catch(err => {
                        });
                }
            },

            deleteShipment(id) {
                this.$confirm(
                    {
                        message: `Confirm delete  item ?`,
                        button: {
                            no: 'No',
                            yes: 'Yes'
                        },
                        /**
                         * Callback Function
                         * @param {Boolean} confirm
                         */
                        callback: confirm => {
                            if (confirm) {
                                WebsiteShipping.delete(this.siteUrl, id).then(res => {
                                    this.$snotify.success("Deleted");
                                    this.getShippingDetails();
                                });
                            }
                        }
                    }
                )

            },

            getShippingDetails() {
                WebsiteShipping.paginate(this.siteUrl)
                    .then(res => {
                        if (res.data.length > 0) {
                            this.shippmentDetails = res.data;
                        }
                    })
                    .catch(err => {
                    });
            },
            getSettings() {
                WebsiteSettingService.paginate(this.siteUrl).then(res => {
                    this.pageSetting = res.data;
                    this.loading = false;
                });
            },
            updateWebsiteSetting(data) {
                this.loading = true;
                this.$confirm(
                    {
                        message: `Are you sure?`,
                        button: {
                            no: 'No',
                            yes: 'Yes'
                        },
                        /**
                         * Callback Function
                         * @param {Boolean} confirm
                         */
                        callback: confirm => {
                            if (confirm) {
                                WebsiteSettingService.update(
                                    this.siteUrl,
                                    this.pageSetting.id,
                                    data
                                ).then(res => {
                                    this.$snotify.success("Settings  Updated");
                                    this.getSettings();
                                });
                            }
                        }
                    }
                )

            }
        },
        computed: {

            siteUrl() {
                return this.$route.params.domainname;
            },

        },
    };
</script>
